import React, { useContext } from "react"
import "./_CommonShared.scss";
import './ConversionPageLayout.scss';
import { graphql } from "gatsby"
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import PaintConverterHeader from "components/headersfooters/PaintConverterHeader";
import DesktopSourcePaintTable from "components/paint-converter/DesktopSourcePaintTable";
import SourcePaintIndicatorBox from "components/paint-converter/SourcePaintIndicatorBox";
import DesktopResultFilters from "components/paint-converter/DesktopResultFilters";
import DesktopResultsGrid from "components/paint-converter/DesktopResultsGrid";
import MobileSourcePaintTable from "components/paint-converter/MobileSourcePaintTable";
import MobileResultsGrid from "components/paint-converter/MobileResultsGrid";
import MobileResultsFilters from "components/paint-converter/MobileResultsFilters";
import PageMeta from "components/texty/PageMeta";
import { FileConnection, Site } from "@GraphQLModels";
import { PaintMatchingContext, SourcePaintListContext } from "@Contexts";
import { CircleGlossySvgDefs, CircleMetallicSvgDefs } from "components/svg";
import { mapToWebsiteInformation } from "graphql/graphql-mappers";
import { getTableDataSetStructuredData } from "@jcharlesworthuk/your-mum-frontend/components/seo/TableDataSetStructuredData";

export type Props = {
    pageContext: {
        codeSetName: string,
        url: string
    };
    data: {
        myFullPaintList: FileConnection,
        paintListFileNames: FileConnection,
        site: Site
    }
}

const baseClass = 'code-set-converter-layout';
const childClass = childClassClosure(baseClass);

/**
 * This page has the actual conversion tables.  It also loads the data as you might navigate straight here from Google
 */
const ConversionPageLayout: React.FunctionComponent<Props> = (props: Props) => {
    const { loadingResults, sourcePaint } = React.useContext(PaintMatchingContext);
    const { sourceCodeSet } = useContext(SourcePaintListContext);
    const website = mapToWebsiteInformation(props.data.site.siteMetadata);
    
    const desktopTableHeight = typeof window !== 'undefined' ? window.innerHeight / 3 : 500;
    const mobileTableHeight = typeof window !== 'undefined' ? window.innerHeight - 160 : 500;
    const structuredDataTable = sourceCodeSet.paints.map(paint => ({ id: paint.indexNumber.toString(), "Product Code": paint.productCode, "Description" : paint.description, "Sheen": paint.sheenAsComparable.toString() }));
    return <div className={baseClass}>
        <PageMeta
            title={`Convert From ${props.pageContext.codeSetName}`}
            description={`The most comprehensive color conversion tool for model paint. Convert from ${props.pageContext.codeSetName} and many other brands with ModelShade`}
            route={`/paint-conversion-chart/${props.pageContext.url}`}>
            {structuredDataTable.length && <script className='structured-data-list' type="application/ld+json">{getTableDataSetStructuredData(sourceCodeSet.fullName, sourceCodeSet.fullName + " paints we have conversions for on ModelShade.com", website, structuredDataTable)}</script> }
        </PageMeta>

        <PaintConverterHeader
            className={childClass('header')}
            title=''
            backArrowRoute={'/paint-conversion-chart/'}
            backArrowRouteMobile={sourcePaint ? sourceCodeSet.route : '/paint-conversion-chart/'} />


        <CircleMetallicSvgDefs />
        <CircleGlossySvgDefs />

        <div className={childClass('container')}>
            <section className={childClass('desktop-view')}>
                <section className={childClass('desktop-view-top')}>
                    <SourcePaintIndicatorBox height={desktopTableHeight} />
                    <DesktopSourcePaintTable height={desktopTableHeight} />
                </section>
                <section className={childClass('desktop-view-bottom')}>
                    <DesktopResultFilters />
                    <DesktopResultsGrid />
                    {loadingResults && <div className={['spinner-block', childClass('results-spinner')].join(' ')}>
                        <div className="spinner spinner-1"></div>
                    </div>
                    }
                </section>
            </section>
            <section className={childClass('mobile-view')}>
                {
                    !sourcePaint
                        ? <MobileSourcePaintTable height={mobileTableHeight} />
                        : <div className={childClass('mobile-view-results')}>
                            <MobileResultsGrid />
                            {loadingResults && <div className={['spinner-block', childClass('results-spinner')].join(' ')}>
                                <div className="spinner spinner-1"></div>
                            </div>}
                            <MobileResultsFilters />
                        </div>
                }
            </section>
        </div>
        {/* <SiteFooter fixed={this.props.results.length === 0 ? FixMode.FixOnDesktop : FixMode.NotFixed} hideOnMobile={true} /> */}
    </div>

}

export default ConversionPageLayout;



export const pageQuery = graphql`
  query ConversionPageLayoutQuery($codeSetName: String) {
      myFullPaintList: allFile(filter: { sourceInstanceName: { eq: "paintLists" }, name: { eq: $codeSetName } }) {
        edges {
            node {
            extension
            dir
            name
            modifiedTime
            childMultiCsvFile {
                items {
                    nc,
                    pc,
                    vc,
                    d,
                    h,
                    s
                }
            }
            }
        }
    }
    site {
            siteMetadata {
                title
                siteUrl
                siteLogoUrl
                siteLogoUrlSquare
                shortDescription
                social {
                  twitter {
                      handle,
                      url
                  }
                }
            }
      }
    paintListFileNames: allFile(filter: { sourceInstanceName: { eq: "paintLists" } } ) {
    edges {
        node {
            name
            }
        }
    }
   }
  `


