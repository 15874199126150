import { PaintMatchingContext, PaintMatchingActionType, SourcePaintListContext } from "@Contexts";
import * as React from "react";
import Fuse from 'fuse.js';
import { Paint } from "@Models";

export const usePaintSearch = () => {
    const { dispatch } = React.useContext(PaintMatchingContext);
    const { sourceCodeSet } = React.useContext(SourcePaintListContext);
    const [ searchTerm, setSearchTerm ] = React.useState('');

    function performSearch (newSearchTerm: string) {
        const normalised = !(/[-\d]+/g).exec(newSearchTerm) && newSearchTerm.length < 3 ? '' : newSearchTerm;
        setSearchTerm(newSearchTerm);
        if (normalised.length == 0) {
            dispatch({ type: PaintMatchingActionType.SourcePaintsFiltered, results: sourceCodeSet.paints });
        } else {
            const options: Fuse.IFuseOptions<Paint> = {
                keys: ['productCode', 'description'],
                threshold: 0.2
            };
            const fuse = new Fuse(sourceCodeSet.paints, options)
            const results = fuse.search(normalised);
            dispatch({ type: PaintMatchingActionType.SourcePaintsFiltered, results: results.map(x => x.item)});
        }
    }

    return {
        searchTerm,
        performSearch
    };
}
