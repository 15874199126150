import * as React from "react";
import { PaintSheen, PaintCodeSet, canMatchOtherSheens } from "@Models";
import './MobileResultsFilters.scss';
import { childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { PaintMatchingContext, SourcePaintListContext } from "@Contexts";
import { createPaintMatchingUrl } from "@Functions";
import { navigate } from "gatsby";

type Props = {}
const baseClass = 'mobile-result-filters';
const childClass = childClassClosure(baseClass);

const MobileResultsFilters: React.FunctionComponent<Props> = (props: Props) => {   
    const { filteredSourcePaints, sourcePaint, allSources, filters, matchPaint } = React.useContext(PaintMatchingContext);
    const { sourceCodeSet } = React.useContext(SourcePaintListContext);
    const [ showPopup, setShowPopup] = React.useState(false);
    const showSheenFilter = sourcePaint && canMatchOtherSheens(sourcePaint.sheenAsComparable);
    const openBrandPopup = () => {
        setShowPopup(true);
    }

    const onSelectPaintCodeSet = (codeSet: PaintCodeSet|undefined) => {
        const newFilters = {
            ...filters,
            singleCodeSetOnly: codeSet
        }
        if (sourcePaint) {
            navigate(createPaintMatchingUrl(sourcePaint, sourceCodeSet, newFilters));
            matchPaint(sourcePaint, newFilters, sourceCodeSet);
        }
    }

    const onChangeSheenFilter = () => {
        const newFilters = {
            ...filters,
            includeAlternateSheens: !filters.includeAlternateSheens
        }
        if (sourcePaint) {
            navigate(createPaintMatchingUrl(sourcePaint, sourceCodeSet, newFilters));
            matchPaint(sourcePaint, newFilters, sourceCodeSet);
        }
    }

    const onFilterClick = (codeSet: PaintCodeSet | undefined) => {
        return () => {
            setShowPopup(false);
            onSelectPaintCodeSet(codeSet);
        }
    }

    const sameSheenText = sourcePaint ? sourcePaint.sheen.toString() + ' only' : '';

    
    return <div className={baseClass}>
    {showSheenFilter &&
        <button
            onClick={onChangeSheenFilter}
            className={childClass('sheen-filter', filters.includeAlternateSheens ? '' : 'selected')}>{sameSheenText}</button>
    }
    <label>Showing</label>
    {filters.singleCodeSetOnly
        ? <h1 className={childClass('brand-filter-indicator')} onClick={openBrandPopup}>
            <img alt={filters.singleCodeSetOnly.brandName} src={`/assets/fixed/logos/${filters.singleCodeSetOnly.slug}.png`} />
        </h1>
        : <button
            className={childClass('brand-filter-indicator', 'brand-all')}
            onClick={openBrandPopup}>
            ALL
    </button>}

    {showPopup && <div className={childClass('brand-popup')}>
        {allSources.filter(x => x.canBeMatchedTo).map((codeSet, i) => <button
            key={i}
            className={childClass('brand-button')}
            onClick={e => onFilterClick(codeSet)()}>
            <img alt={codeSet.brandName} src={`/assets/fixed/logos/${codeSet.slug}.png`} />
        </button>)}
        <button
            className={childClass('brand-button', 'all')}
            onClick={e => onFilterClick(undefined)()}>
            ALL
    </button>
    </div>
    }
</div>
}


export default MobileResultsFilters;