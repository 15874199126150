import * as React from "react";
import { PaintSheen, PaintCodeSet, canMatchOtherSheens } from "@Models";
import './DesktopResultFilters.scss';
import { childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { PaintMatchingContext, SourcePaintListContext } from "@Contexts";
import { createPaintMatchingUrl } from "@Functions";
import { navigate } from "gatsby";

type Props = {}

const baseClass = 'desktop-result-filters';
const childClass = childClassClosure(baseClass);

const DesktopResultFilters: React.FunctionComponent<Props> = (props: Props) => {    
    const { sourcePaint, allSources, filters, matchPaint } = React.useContext(PaintMatchingContext);
    const { sourceCodeSet } = React.useContext(SourcePaintListContext);
    const showSheenFilter = sourcePaint && canMatchOtherSheens(sourcePaint.sheenAsComparable);

    const onSelectPaintCodeSet = (codeSet: PaintCodeSet|undefined) => {
        const newFilters = {
            ...filters,
            singleCodeSetOnly: codeSet
        }
        if (sourcePaint) {
            navigate(createPaintMatchingUrl(sourcePaint, sourceCodeSet, newFilters));
            matchPaint(sourcePaint, newFilters, sourceCodeSet);
        }
    }

    const onChangeSheenFilter = () => {
        const newFilters = {
            ...filters,
            includeAlternateSheens: !filters.includeAlternateSheens
        }
        if (sourcePaint) {
            navigate(createPaintMatchingUrl(sourcePaint, sourceCodeSet, newFilters));
            matchPaint(sourcePaint, newFilters, sourceCodeSet);
        }
    }

    const sameSheenText = sourcePaint ? sourcePaint.sheen.toString() + ' only' : '';
    return <div className={baseClass}>
        <label>Show Results For:</label>
        <button 
            className={childClass('brand-button', 'all-brands', !filters.singleCodeSetOnly ? 'selected' : undefined)}
            onClick={e => onSelectPaintCodeSet(undefined)}>
            ALL
        </button>
        {allSources.filter(x => x.canBeMatchedTo).map((codeSet, i) => <button 
            key={i}
            className={childClass('brand-button', codeSet.slug == (filters.singleCodeSetOnly || {}).slug ? 'selected' : '')}
            onClick={e => onSelectPaintCodeSet(codeSet)}>
            <img alt={codeSet.brandName} src={`/assets/fixed/logos/${codeSet.slug}.png`} />
        </button>)}
        { showSheenFilter &&
            <button 
                    onClick={onChangeSheenFilter}
                    className={childClass('sheen-filter', filters.includeAlternateSheens ? '' : 'selected')}>{sameSheenText}</button>
        }
    </div>
}

export default DesktopResultFilters;