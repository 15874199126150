import * as React from "react";
import { PaintSheen } from "@Models";
import { PaintDropSvg } from 'components/svg';
import './SourcePaintIndicatorBox.scss';
import { ArrowSvg } from 'components/svg/ArrowSvg';
import LinkWrapper from "components/texty/LinkWrapper";
import { childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { PaintMatchingContext, SourcePaintListContext } from "@Contexts";
import PaintColourIndicator from "components/indicators/PaintColourIndicator";


type Props = {
    height: number;
}

const baseClass = 'source-paint-indicator-box';
const childClass = childClassClosure(baseClass);

const SourcePaintIndicatorBox: React.FunctionComponent<Props> = (props: Props) => {
    const { sourceCodeSet } = React.useContext(SourcePaintListContext);
    const { sourcePaint } = React.useContext(PaintMatchingContext);


    return <div className={baseClass} style={{ 'height': props.height + 74 }}>
        <LinkWrapper className={childClass('brand-switcher')} to='/paint-conversion-chart/'>
            <img src={`/assets/fixed/logos/${sourceCodeSet.slug}.png`} className={childClass('brand-logo')} />
        </LinkWrapper>
        {sourcePaint ?
            <React.Fragment>
                <PaintColourIndicator className={childClass('indicator')} paint={sourcePaint} />
                <h2 className={childClass('code')} >{sourcePaint.productCode}</h2>
                <h2 className={childClass('description')} >{sourcePaint.description}</h2>
                <h4 className={childClass('sheen')} >{sourcePaint.sheen != PaintSheen.Unknown ? sourcePaint.sheen.toString() : ''}</h4>
            </React.Fragment>
            : <h2 className={childClass('no-paint-placeholder')}>Convert From <ArrowSvg /></h2>}
    </div>
}

export default SourcePaintIndicatorBox;