import * as React from "react";
import { Paint, PaintMatchingResult, PaintSheen } from "@Models";
import { CircleMattSvg, CircleMetallicSvg, CircleMetallicSvgDefs, MinusIconSvg, PlusIconSvg, StarSvg } from 'components/svg';
import './DesktopResultsGrid.scss';
import * as ReactGA from 'react-ga';
import { GaEventCategory } from "@Models";
import { childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { UserBasketContext, UserBasketActionType, PaintMatchingContext } from '@Contexts';
import { addPaintToStoredBasket, removeFromStoredBasket } from "@Functions";
import PaintColourIndicator from "components/indicators/PaintColourIndicator";

type Props = {
}

const baseClass = 'desktop-results-grid';
const childClass = childClassClosure(baseClass);

const starFillColour = '#939596';

type ItemProps = {result: PaintMatchingResult, index: number, myPaintList: Paint[], onClickResult: (result: PaintMatchingResult, wasSelected: boolean) => void};

const DesktopResultsGridItem: React.FunctionComponent<ItemProps> = (props: ItemProps) => {
    const isStored = props.myPaintList.some(x => x.getUniquePaintCode() == props.result.paint.getUniquePaintCode());
    const starScore = props.result.matchStrength;
    return <li key={props.index} className={childClass('item', isStored ? 'saved': '')}>
        <img alt={props.result.codeSet.brandName} src={`/assets/fixed/logos/${props.result.codeSet.slug}.png`} className={childClass('brand-logo')} />
        <PaintColourIndicator className={childClass('indicator')} paint={props.result.paint} onClick={() => props.onClickResult(props.result, isStored)} />
        { !isStored && <PlusIconSvg className={childClass('basket-icon', 'plus')} /> }
        { isStored && <MinusIconSvg className={childClass('basket-icon', 'minus')} /> }
        <p className={childClass('code')}>{props.result.paint.productCode}</p>
        {/* <button className={childClass('plus-button', isStored ? 'active': '')} onClick={() => props.onClickResult(props.result, isStored)}>+</button> */}
        <h4 className={childClass('description')}>{props.result.paint.description}</h4>
        <div className={childClass('stars')}>
            <StarSvg filledColour={starFillColour} fillStep={starScore - 0} />
            <StarSvg filledColour={starFillColour} fillStep={starScore - 1} />
            <StarSvg filledColour={starFillColour} fillStep={starScore - 2} />
            <StarSvg filledColour={starFillColour} fillStep={starScore - 3} />
            <StarSvg filledColour={starFillColour} fillStep={starScore - 4} />
        </div>
        {/* <h4>Delta={result.deltaE} Base={result.breakdown.baseScoreOutOfFive} Mods={Object.keys(result.breakdown.modifiersList).map(k => `${k}=${result.breakdown.modifiersList[k]}`)} </h4> */}
    </li>
}

const DesktopResultsGrid : React.FunctionComponent<Props> = (props: Props) => {
    const { results, sourcePaint, loadingResults } = React.useContext(PaintMatchingContext);
    const { myPaintList, dispatch: userBasketDispatch } = React.useContext(UserBasketContext);
    const sourcePaintIsSelected =sourcePaint && !loadingResults ? true : false;

    const onClickResult = (result: PaintMatchingResult, wasSelected: boolean) => {
        if (wasSelected) {
            ReactGA.event({ category: GaEventCategory.Basket, action: 'Remove Paint (from results)', label: result.getUniquePaintCode() });
            removeFromStoredBasket(result.paint);            
            userBasketDispatch({ type: UserBasketActionType.RemovePaintFromMyList, item: result.paint });
        } else {
            ReactGA.event({ category: GaEventCategory.Basket, action: 'Save Paint', label: result.getUniquePaintCode() });
            addPaintToStoredBasket(result.paint);
            userBasketDispatch({ type: UserBasketActionType.AddPaintToMyList, item: result.paint });
        }
    }


    return <div className={baseClass}>
    {sourcePaintIsSelected && results.length == 0
        ? <h2 className={childClass('no-matches')}>No matches found</h2>
        : null}
    <ul className={childClass('list')}>
        {results.map((result, index) => <DesktopResultsGridItem key={index} result={result} index={index} onClickResult={onClickResult} myPaintList={myPaintList} />)}
    </ul>
</div>

}

export default DesktopResultsGrid;