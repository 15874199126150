import * as React from "react";
import { Paint, PaintSheen } from "@Models";
import './DesktopSourcePaintTable.scss';
import { Table, Column, TableCellProps, TableCellDataGetterParams } from 'react-virtualized/dist/es/Table'
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer'
import { usePaintSearch } from "@Hooks";
import { PaintMatchingContext, SourcePaintListContext } from "@Contexts";
import { childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { createPaintMatchingUrl } from "@Functions";
import { navigate } from "gatsby";

type Props = { height: number }

const baseClass = 'desktop-source-paint-table';

const childClass = childClassClosure(baseClass);

function colourCellDataGetter({
    rowData,
}: TableCellDataGetterParams) {
    return (rowData as Paint).colour.hex;
}

function sheenCellDataGetter({
    rowData,
}: TableCellDataGetterParams) {
    return (rowData as Paint).sheenAsComparable == PaintSheen.Unknown ? '' : (rowData as Paint).sheen.toString();
}


function colourCellRenderer({
    cellData,
}: TableCellProps): React.ReactNode {
    if (cellData == null) {
        return '';
    } else {
        return <span className='colour-box' style={{ 'backgroundColor': cellData }}>&nbsp; </span>
    }
}

const DesktopSourcePaintTable: React.FunctionComponent<Props> = (props: Props) => {
    const { filteredSourcePaints, sourcePaint, filters } = React.useContext(PaintMatchingContext);
    const { sourceCodeSet } = React.useContext(SourcePaintListContext);
    const { searchTerm, performSearch }  = usePaintSearch();
    const selectedPaintIndex = sourcePaint ? filteredSourcePaints.indexOf(sourcePaint) : -1;
    
    const changeSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = e.target.value;
        performSearch(newSearchTerm);
    }

    return <div className={baseClass}>
    <form className={childClass('search-form')} onSubmit={(e) => {e.preventDefault() }}>
        <input
            type='search'
            placeholder='Search...'
            className={childClass('search-input')}
            value={searchTerm}
            onChange={changeSearchTerm} />
    </form>
    <div className={childClass('table-wrap')}>
        {/* <ul className={childClass('non-virtual-list')}>
            {filteredSourcePaints.map((paint, index) => <li key={paint.indexNumber} onClick={() => navigate(createPaintMatchingUrl(paint, sourceCodeSet, filters))} className={childClass('non-virtual-list-item', index === selectedPaintIndex  ?'selected' : '')}>
                <div className={childClass('non-virtual-list-item-colour')} style={{ 'backgroundColor': paint.colour.hex }}></div>
                <div className={childClass('non-virtual-list-item-code')}>{paint.productCode}</div>
                <div className={childClass('non-virtual-list-item-description')}>{paint.description}</div>
                <div className={childClass('non-virtual-list-item-sheen')}>{paint.sheen.toString()}</div>
            </li>)}
        </ul> */}
        <AutoSizer disableHeight>
            {({ width }) => (
                <Table
                    headerHeight={20}
                    rowHeight={30}
                    width={width}
                    height={props.height}
                    rowCount={filteredSourcePaints.length}
                    rowGetter={({ index }) => filteredSourcePaints[index]}
                    disableHeader={true}
                    rowClassName={i => i.index == selectedPaintIndex ? 'selected' : ''}
                    onRowClick={params => navigate(createPaintMatchingUrl(params.rowData as Paint, sourceCodeSet, filters))}>
                    <Column
                        label='Colour'
                        dataKey='colour'
                        cellDataGetter={colourCellDataGetter}
                        cellRenderer={colourCellRenderer}
                        width={100}
                    />
                    <Column
                        width={200}
                        label='productCode'
                        dataKey='productCode'
                    />
                    <Column
                        width={200}
                        label='Description'
                        dataKey='description'
                    />                            
                    <Column
                        width={100}
                        label='Sheen'
                        dataKey='sheen'
                        className='sheen-cell'
                        cellDataGetter={sheenCellDataGetter}
                    />
                </Table>
            )}
        </AutoSizer>
    </div>
</div>
}

export default DesktopSourcePaintTable;