import * as React from "react";
import { PaintMatchingResult, PaintSheen } from "@Models";
import { PaintDropSvg, StarSvg } from 'components/svg';
import './MobileResultsGrid.scss';
import { childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { PaintMatchingContext, SourcePaintListContext } from "@Contexts";

type Props = {}

const baseClass = 'mobile-results-grid';
const childClass = childClassClosure(baseClass);

type ItemProps = {result: PaintMatchingResult, index: number };


const MobileResultsGridItem: React.FunctionComponent<ItemProps> = (props: ItemProps) => {
    const starScore = props.result.matchStrength;
    return <li key={props.index} className={childClass('item')}>
        <PaintDropSvg colour={props.result.paint.colour.hex} />
        <h3 className={childClass('code')}>{props.result.paint.productCode}</h3>
        <div className={childClass('match-name-wrap')}>
            <h4>{props.result.paint.description}</h4>
            <div className={childClass('sheen-star-wrap')}>
                <p>{props.result.paint.sheen.toString()}</p>
                <div className={childClass('stars')}>
                    <StarSvg fillStep={starScore - 0} />
                    <StarSvg fillStep={starScore - 1} />
                    <StarSvg fillStep={starScore - 2} />
                    <StarSvg fillStep={starScore - 3} />
                    <StarSvg fillStep={starScore - 4} />
                </div>
                {/* <p className={childClass('debug-info')}>D={Math.round(result.deltaE * 100) / 100} {result.paint.colour.l}:{result.paint.colour.a}:{result.paint.colour.b} {result.chartMaker.length ? 'C' : ''}</p> */}
            </div>

        </div>
        <div className={childClass('rhs-action')}>
            <img alt={props.result.codeSet.brandName} src={`/assets/fixed/logos/${props.result.codeSet.slug}.png`} className={childClass('brand-logo')} />
        </div>
    </li>

}

const MobileResultsGrid: React.FunctionComponent<Props> = (props: Props) => {
    const { results, sourcePaint, loadingResults } = React.useContext(PaintMatchingContext);
    const { sourceCodeSet } = React.useContext(SourcePaintListContext);
    const sourcePaintIsSelected =sourcePaint && !loadingResults ? true : false;
        
        return <div className={baseClass}>
            <div className={childClass('header-bar')}>
                <div className={childClass('header-bar-logowrap')}>
                    <PaintDropSvg colour={sourcePaint.colour.hex} />
                    <h2 className={childClass('header-bar-logowrap-code')} >{sourcePaint.productCode}</h2>
                    <div className={childClass('header-bar-info-block')} >
                        <p className={childClass('header-bar-info-block-description')} >{sourcePaint!.description}</p>
                        <p className={childClass('header-bar-info-block-sheen')} >{sourcePaint.sheen.toString()}</p>
                    </div>
                    <img alt={sourceCodeSet!.brandName} src={`/assets/fixed/logos/${sourceCodeSet.slug}.png`} />
                </div>
            </div>
            { sourcePaintIsSelected && results.length == 0 
                ? <h2 className={childClass('no-matches')}>No matches found</h2>
                : null }
            <ul className={childClass('list')}>
                {results.map((result, index) => <MobileResultsGridItem key={index} result={result} index={index} />)}
            </ul>
        </div>
    }



export default MobileResultsGrid;