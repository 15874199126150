import * as React from "react";
import { Paint, PaintSheen } from "@Models";
import './MobileSourcePaintTable.scss';
import { Table, Column, TableCellProps, TableCellDataGetterParams } from 'react-virtualized/dist/es/Table'
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer'
import { PaintDropSvg } from 'components/svg';
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { PaintMatchingContext, SourcePaintListContext } from "@Contexts";
import { usePaintSearch } from "@Hooks";
import { navigate } from 'gatsby';
import { createPaintMatchingUrl } from "@Functions";

type Props = { height: number }

const baseClass = 'mobile-source-paint-table';

const childClass = childClassClosure(baseClass);

function colourCellDataGetter({
    rowData,
}: TableCellDataGetterParams) {
    return (rowData as Paint).colour.hex;
}


function paintCellDataGetter({
    rowData,
}: TableCellDataGetterParams) {
    return (rowData as Paint);
}


function colourCellRenderer({ cellData, }: TableCellProps): React.ReactNode {
    if (cellData == null) {
        return '';
    } else {
        const colourString = cellData as string;
        return <span className={childClass('colour-cell')}>
            <PaintDropSvg colour={colourString} />
        </span>
    }
}

function detailsCellRenderer({ cellData, }: TableCellProps): React.ReactNode {
    if (cellData == null) {
        return '';
    } else {
        const paint = cellData as Paint;
        return <div className={childClass('match-name-wrap')}>
        <h4>{paint.description}</h4>
        <p>{paint.sheenAsComparable != PaintSheen.Unknown ? paint.sheen.toString() : ''}</p>
        </div>
        
    }
}

const MobileSourcePaintTable: React.FunctionComponent<Props> = (props: Props) => {
    const { filteredSourcePaints, sourcePaint, filters } = React.useContext(PaintMatchingContext);
    const { sourceCodeSet } = React.useContext(SourcePaintListContext);
    const { searchTerm, performSearch }  = usePaintSearch();
    const selectedPaintIndex = sourcePaint ? filteredSourcePaints.indexOf(sourcePaint) : -1;

    const onRowClick = (paint: Paint)  => {
        navigate(createPaintMatchingUrl(paint, sourceCodeSet, filters));
    }
    
    const changeSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = e.target.value;
        performSearch(newSearchTerm);
    }
        return <div className={baseClass}>
            <div className={childClass('header-bar')}>
                <h4 className={childClass('header-bar-label')}>Convert From...</h4>
                <div className={childClass('header-bar-logowrap')}>
                    <img src={`/assets/fixed/logos/${sourceCodeSet.slug}.png`} />
                </div>
            </div>

            <form className={childClass('search-form')} onSubmit={(e) => { e.preventDefault() }}>
                <input
                    type='search'
                    placeholder='Search...'
                    className={childClass('search-input')}
                    value={searchTerm}
                    onChange={changeSearchTerm} />
            </form>
            <div className={childClass('table-wrap')}>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <Table
                            headerHeight={20}
                            rowHeight={50}
                            width={width}
                            height={props.height}
                            rowCount={filteredSourcePaints.length}
                            rowGetter={({ index }) => filteredSourcePaints[index]}
                            disableHeader={true}
                            rowClassName={i => i.index == selectedPaintIndex ? 'selected' : ''}
                            onRowClick={params => onRowClick(params.rowData as Paint)}>
                            <Column
                                label='Colour'
                                dataKey='colour'
                                cellDataGetter={colourCellDataGetter}
                                cellRenderer={colourCellRenderer}
                                width={100}
                            />
                            <Column
                                width={200}
                                label='productCode'
                                dataKey='productCode'
                                className='code-cell'
                            />
                            <Column
                                width={350}
                                label='Description'
                                dataKey='description'
                                cellDataGetter={paintCellDataGetter}
                                cellRenderer={detailsCellRenderer}
                            />
                        </Table>
                    )}
                </AutoSizer>
            </div>
        </div>
    }


export default MobileSourcePaintTable;