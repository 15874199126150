import * as React from "react";
import { Paint, PaintSheen } from "@Models";
import { ClassableProps } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { CircleGlossySvg, CircleMattSvg, CircleMetallicSvg } from "components/svg";

type Props = ClassableProps & {
    paint: Paint
    onClick: () => void;
}

const PaintColourIndicator : React.FunctionComponent<Props> = (props: Props) => {
    switch(props.paint.sheenAsComparable) {
        case PaintSheen.Gloss: return <CircleGlossySvg className={props.className} hex={props.paint.colour.hex} onClick={props.onClick} /> 
        case PaintSheen.Metallic: return <CircleMetallicSvg className={props.className} hex={props.paint.colour.hex} onClick={props.onClick}  /> 
        default: return <CircleMattSvg className={props.className} hex={props.paint.colour.hex} onClick={props.onClick}  /> 
    }
}

export default PaintColourIndicator;